<template>
  <section class="artists">
    <header class="artists__title-box">
      <h2 class="artists__title-box_title">Artists</h2>
      <div class="artists__title-box_right">
        <PublicSelect
          :items="sortData"
          :selected="cardData.sort"
          @input="sortArtists($event.id)"
        ></PublicSelect>
        <FilterBlock
          class="filter--mobile-full"
          style="margin-left: 15px"
          :items="filters"
          :search="search"
          @open="openFilters = $event"
          @selectFilter="selectFilter"
          @apply="refreshSelectedFilters"
          @clear="refreshSelectedFilters(true)"
          @changeSearch="changeSearch"
        ></FilterBlock>
        <ViewToggle
          @input="updateView"
          designtype="public"
          :viewType="cardData.viewType"
        ></ViewToggle>
      </div>
    </header>
    <div class="artists__nav">
      <router-link
        v-for="(item, index) in nav"
        :key="`nav-${index}`"
        :to="`/artists${item.hash}`"
        class="artists__nav-button"
        :class="{
          'artists__nav-button--white': item.active,
          'artists__nav-button--outline-white': !item.active,
        }"
      >
        {{ item.text }}</router-link
      >
    </div>
    <IconLoader v-if="loading" class="artists__loader loader--white loader--lg"></IconLoader>
    <template v-else>
      <template v-if="cardData.viewType === 'card'">
        <div v-if="artists.length > 0" class="artists__artists-box">
          <router-link
            v-for="(artist, key) in artists"
            :key="`artist-${key}`"
            :to="{ path: `/artists/${artist.slug}` }"
            v-slot="{ navigate }"
            custom
          >
            <div class="artists__artists-box_card" @click="navigate">
              <div
                class="image"
                :style="{
                  backgroundImage: `url(${artist.search_image}`,
                }"
                @click="$router.push({ path: `/artists/${artist.slug}` })"
              >
                <div class="image__preview"><p>Click to View</p></div>
              </div>
              <h3 class="name">{{ artist.full_name }}</h3>
              <div class="country">
                <span v-for="i in artist.regions">{{ i.title }}<span>,</span></span>
              </div>
            </div>
          </router-link>
        </div>
        <h6 class="exhibition__empty" v-else>NO ARTISTS</h6>
        <div v-if="showLoadMoreButton && hasMorePages" class="artists__button-box">
          <BaseButton
            class="button button--xs button--white"
            text="LOAD MORE"
            @click.native="handleLoadMoreClick"
          />
        </div>
      </template>
      <template v-else>
        <div v-if="sortedArtistsList.length > 0" class="artists__body">
          <div
            v-for="(column, index) in sortedArtistsList"
            :key="`column-${index}`"
            class="artists__body__column"
          >
            <router-link
              v-for="(item, index) in column"
              :key="`item-${index}`"
              class="artists__body__column_item"
              :to="{ path: `/artists/${item.slug}` }"
            >
              <span
                class="artists__body__column_name"
              >
              <!-- :class="{ 'artists__body__column_name--disabled': !item.hasArtworks }" -->
                {{ item.reversed_full_name }}
              </span>
            </router-link>
          </div>
        </div>
        <h6 class="exhibition__empty" v-else>NO ARTISTS</h6>
      </template>
    </template>
  </section>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/buttons/BaseButton.vue";
import PublicSelect from "@/components/inputs/PublicSelect.vue";
import FilterBlock from "@/components/FilterPublic.vue";
import IconLoader from "@/components/icons/IconLoader.vue";
import ViewToggle from "@/components/ViewToggle.vue";
import { sortedColumns } from "@/helper.js";

export default {
  components: {
    BaseButton,
    PublicSelect,
    FilterBlock,
    IconLoader,
    ViewToggle,
  },

  data() {
    return {
      sortData: [
        { id: "last_name_a_z", title: "Last name (A-Z)" },
        { id: "last_name_z_a", title: "Last name (Z-A)" },
      ],
      cardData: {
        sort: "last_name_a_z",
        page: 1,
        region: "",
        market: "",
        viewType: "list",
      },
      nav: [
        {
          active: true,
          text: "Our Artists",
          key: "",
          count: 0,
          hash: "#artists",
        },
        {
          active: false,
          text: "Secondary Market",
          key: "secondary",
          count: 0,
          hash: "#secondary-market",
        },
        {
          active: false,
          text: "All",
          key: "all",
          count: 0,
          hash: "#all",
        },
      ],
      navActive: {},
      openFilters: false,
      loading: false,
      sortedArtistsList: [],
      showLoadMoreButton: true,
      loadingMore: false,
    };
  },
  head: {
    title: function () {
      return {
        inner: "Artists we represent browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: "Artists we represent browngrotta arts",
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Artists we represent browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Artists we represent browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Artists we represent browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: "Description Artists we represent browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "Artists we represent browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: "Description Artists we represent browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "Artists we represent browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (!val.hash) {
          this.$router.push("/artists#artists");
        } else this.clickNav(val.hash);
      },
      immediate: true,
      deep: true,
    },
  },
  async created() {
    this.loading = true;
    // this.navActive = this.nav[0];

    // await this.apiGetArtists({
    //   sort: this.cardData.sort,
    //   regions: "",
    //   page: this.cardData.page,
    // });
    this.sortedArtistsList = sortedColumns(this.artists, this.getColumnsAmount); //to get columns
    await this.apiGetFiltersBlock({
      list: "regions",
      type: "content",
      search: this.search,
      isFirst: true,
    });

    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Artists",
        to: "/artists",
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
    this.loading = false;
  },

  computed: {
    ...mapGetters("publicapi/artists", {
      artists: "getArtists",
      hasMorePages: "getHasMorePages",
    }),
    ...mapGetters("publicapi/filters", {
      filters: "getFilters",
      search: "getSearch",
      selectedFilters: "getSelectedFilters",
    }),
    getColumnsAmount() {
      const width = window.innerWidth;
      if (width < 540) {
        return 1;
      } else if (width < 768) {
        return 2;
      } else if (width < 1024) {
        return 3;
      } else {
        return 4;
      }
    },
  },

  methods: {
    ...mapActions("publicapi/artists", ["apiGetArtists"]),
    ...mapActions("publicapi/filters", ["apiGetFiltersBlock"]),
    ...mapMutations("publicapi/filters", ["updateFilters", "updateSelectedFilters", "clear"]),
    ...mapMutations(["setBreadcrumbs"]),

    async updateView(val) {
      this.viewType = val;
      let data = { ...this.cardData, page: val === "card" ? 1 : this.cardData.page, viewType: val };
      let success = await this.apiGetArtists(data);
      if (success) {
        this.cardData = { ...data };
        this.sortedArtistsList = sortedColumns(this.artists, this.getColumnsAmount); //to get columns
      }
    },

    async sortArtists(sort) {
      let data = { ...this.cardData, sort, page: 1 };
      let success = await this.apiGetArtists(data);
      if (success) {
        this.cardData = { ...data };
        this.sortedArtistsList = sortedColumns(this.artists, this.getColumnsAmount); //to get columns
      }
    },

    async loadMore() {
      if (this.loadingMore || !this.hasMorePages) return;
      this.loadingMore = true;

      let data = { ...this.cardData, page: this.cardData.page + 1 };
      await this.apiGetArtists(data);
      this.cardData = { ...data };
      this.sortedArtistsList = sortedColumns(this.artists, this.getColumnsAmount); //to get columns
      this.loadingMore = false;
    },
    handleLoadMoreClick() {
      this.showLoadMoreButton = false;
      this.loadMore();
      this.enableInfiniteScroll();
    },

    enableInfiniteScroll() {
      window.addEventListener("scroll", this.handleScroll);
    },

    handleScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      const bottomPosition = document.documentElement.scrollHeight;

      if (scrollPosition >= bottomPosition - 100) {
        this.loadMore();
      }
    },

    selectFilter(val) {
      this.updateFilters(val);
      if (val.apply) {
        this.refreshSelectedFilters();
      }
    },
    async refreshSelectedFilters(clear) {
      if (clear) {
        await this.clear();
      }
      await this.updateSelectedFilters();
      this.filterTable();
    },
    async filterTable() {
      this.loading = true;
      let data = { ...this.cardData, page: 1 };
      this.selectedFilters.forEach(el => {
        if (el.key === "price") {
          data.price_start = "";
          data.price_end = "";
          el.items.forEach(i => {
            data[i.key] = i.val;
          });
        } else {
          let filters = el.items.map(i => {
            return i.id;
          });
          data[el.key] = filters.join(",");
        }
      });
      let success = await this.apiGetArtists(data);
      this.loading = false;
      if (success) {
        this.cardData = data;
        this.sortedArtistsList = sortedColumns(this.artists, this.getColumnsAmount); //to get columns
      }
    },
    clickNav(hash) {
      this.nav.map(item => {
        if (item.hash === hash) {
          item.active = true;
          this.navActive = item;
          this.cardData.market = item.key;
          this.filterTable();
        } else item.active = false;
      });
    },

    async changeSearch(search) {
      await this.apiGetFiltersBlock({ list: "regions", type: "content", search });
    },
  },
  mounted() {
    if (!this.showLoadMoreButton) {
      this.enableInfiniteScroll();
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
  font-size: 1.8rem;
  line-height: 2.4rem;
  @media screen and (max-width: $xs) {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}

.artists {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 19.7rem;
  padding: 0rem 10.5rem 23.5rem;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 2;
  @media screen and (max-width: $xs) {
    margin-top: 15rem;
    padding: 0rem 2rem 11rem;
  }

  &__title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    &_right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      @media screen and (max-width: $xs) {
        justify-content: space-between;
        .view,
        .form-control {
          order: 1;
        }
        .view {
          margin-left: 15px;
        }
        .filter {
          order: 2;
          margin-left: 0 !important;
          width: 100%;
        }
      }
    }
    &_title {
      font-size: 4rem;
      line-height: 5rem;
      @media screen and (max-width: $xs) {
        font-size: 3.5rem;
        line-height: 4.3rem;
        margin-bottom: 30px;
      }
    }
  }
  &__empty {
    font-size: 20px;
    font-weight: 600;
    padding: 10px 10px 50px;
    text-align: center;
    text-transform: uppercase;
  }
  &__nav {
    padding: 6rem 0 4rem;
    display: flex;
    @media screen and (max-width: $xs) {
      width: 100%;
      padding: 45px 0 20px;
      flex-wrap: wrap;
    }
    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 19px;
      width: max-content;
      height: 4rem;
      border: 1px solid transparent;
      font-family: $fontFrutiger;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1;
      letter-spacing: -0.4px;
      text-decoration: none;
      padding: 0 20px;
      margin-right: 10px;
      transition: all 0.3s ease;

      &--outline-white {
        background-color: transparent;
        color: $white;
        border-color: $white;
        &:hover {
          background-color: rgba($color: $white, $alpha: 0.1);
          color: $white;
        }
      }
      &--white {
        background-color: $white;
        color: $black;
        &:hover {
          background-color: $light2Grey;
        }
      }
      @media screen and (max-width: $xs) {
        height: 30px;
        padding: 0 15px;
        margin-bottom: 10px;
        font-size: 12px;
        text-transform: uppercase;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  &__artists-box {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 3rem;
    grid-row-gap: 6.8rem;
    @media screen and (max-width: $md) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: $xs) {
      grid-template-columns: 1fr;
    }

    &_card {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      &-link {
        display: block;
        text-decoration: none;
      }
      .image {
        position: relative;
        height: 27vw;
        width: 100%;
        background-size: cover;
        background-position: center;
        margin-bottom: 25px;
        border-radius: 4px;
        @media screen and (max-width: $xs) {
          height: 90vw;
        }

        &__preview {
          position: absolute;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-self: center;
          opacity: 0;
          border-radius: 4px;
          transition: all 0.3s ease;

          p {
            width: 100%;
            text-align: center;
            font-size: 3rem;
            line-height: 1.67;
            letter-spacing: -1px;
          }
        }

        &:hover {
          .image__preview {
            opacity: 1;
            background-color: rgba($color: $blue, $alpha: 0.83);
          }
        }
      }

      .name {
        font-size: 2.4rem;
        line-height: 3.1rem;
        margin-top: 1.2rem;
        font-weight: 500;
        // &:hover {
        //   text-decoration: underline;
        // }
      }

      .country {
        display: flex;
        flex-wrap: wrap;
        gap: 0 4px;
        margin-top: 0.8rem;
        @media screen and (max-width: $xs) {
          font-size: 1.4rem;
          line-height: 2.5rem;
        }
        span {
          &:last-of-type {
            span {
              display: none;
            }
          }
        }
      }
    }
  }
  &__body {
    display: flex;
    justify-content: space-between;
    gap: 0 12px;
    width: 90%;
    margin-top: 70px;
    @media only screen and (max-width: $xs) {
      width: 100%;
      margin-top: 0;
      display: block;
      text-align: center;
    }

    &__column {
      &_item {
        display: block;
        text-decoration: none;
        @media screen and (max-width: $xs) {
          text-align: left;
        }
      }

      &_name {
        font-size: 18px;
        line-height: 1.55;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
        &--disabled {
          color: $grey;
        }
      }
    }
  }

  &__button-box {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    margin-top: 7rem;

    .button {
      min-width: 180px;
    }
  }
  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>

